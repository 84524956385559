<template>
  <v-container>
    <v-row>
      <v-col>
        <v-tabs :value="tab" vertical ref="tabAdmonition">
          <v-tab-item value="tab-show">
            <s-crud
              pdf
              title="Contingencias Laborales"
              @add="addAllowance($event)"
              @edit="editAllowance($event)"
              excel
              edit
              add
              remove
              addNoFunction
              noBorder
              :config="this.config"
              @save="saveWac($event)"
              ref="crudworkerallowance"
              :filter="filter"
              search-input
            >
              <template v-slot:options>
                <v-tooltip bottom="">
                  <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" small elevation="0" @click="AddMasive(true)">
                      <v-icon style="font-size: 16px">far fa-arrow-alt-circle-up</v-icon>
                    </v-btn>
                  </template>
                  <span>Contingencias Masivas</span></v-tooltip
                >
              </template>
              <template v-slot:filter>
                <v-card>
                  <v-card-text>
                    <v-row class="justify-center">
                      <v-col cols="2" class="s-col-form">
                        <s-switch label="Mostrar todas las contingecias" v-model="filter.Everybody" />
                      </v-col>
                      <v-col cols="2" class="s-col-form">
                        <s-select-definition
                          :def="1118"
                          v-model="filter.TypeGroup"
                          label="Permisos"
                          @input="selectType($event, wac)"
                          :disabled="filter.Everybody"
                        ></s-select-definition>
                      </v-col>
                      <v-col cols="2" class="s-col-form">
                        <s-select-definition
                          :def="1040"
                          :dgr="filter.TypeGroup"
                          v-model="filter.TypeIncident"
                          label="Incidencia"
                          @input="selectIncident($event, filter.TypeGroup)"
                          :disabled="filter.Everybody"
                        ></s-select-definition>
                      </v-col>

                      <v-col cols="2" class="s-col-form" v-if="$fun.getSecurity().IsLevelPayroll">
                        <s-select-payroll-allow v-model="filter.TypePayroll" label="Tipo Planilla" :disable="filter.Everybody" />
                      </v-col>

                      <v-col cols="2" class="s-col-form" v-if="$fun.getSecurity().IsLevelAdmin">
                        <s-select-definition :def="1060" v-model="filter.TypePayroll" label="Tipo Planilla" :disabled="filter.Everybody" />
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-date label="Fecha Inicio" v-model="filter.WacBeginDate" clearable></s-date>
                      </v-col>
                      <v-col lg="2" class="s-col-form">
                        <s-date label="Fecha Fin" v-model="filter.WacEndDate" clearable></s-date>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </s-crud>
          </v-tab-item>
          <v-tab-item value="tab-edit">
            <template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-col>
                    <v-card-text class="px-3">
                      <v-container class="white lighten-5">
                        <v-row>
                          <v-col cols="3" class="s-col-form" v-if="this.$fun.getSecurity().IsLevelPayroll">
                            <s-select-payroll-allow v-model="wac.TypePayroll" label="Tipo Planilla" />
                          </v-col>
                          <v-col cols="3" class="s-col-form" v-if="this.$fun.getSecurity().IsLevelAdmin">
                            <s-select-definition :def="1060" v-model="wac.TypePayroll" label="Tipo Planilla" />
                          </v-col>
                          <v-col cols="9" class="s-col-form">
                            <s-select-worker
                              label="Datos de trabajadores"
                              :text="wac.WkrName"
                              :typepayrollallow="wac.TypePayroll"
                              v-model="wac.DataWorker"
                              returnObject
                            ></s-select-worker>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3" class="s-col-form">
                            <s-select-definition
                              :def="1118"
                              v-model="wac.TypeGroup"
                              label="Permisos"
                              @input="selectType($event, wac)"
                            ></s-select-definition>
                          </v-col>
                          <v-col cols="3" class="s-col-form">
                            <s-select-definition
                              :def="1040"
                              :dgr="wac.TypeGroup"
                              v-model="wac.TypeIncident"
                              label="Incidencia"
                              @input="selectIncident($event, wac.TypeGroup)"
                            ></s-select-definition>
                          </v-col>
                          <v-col v-if="wac.TypePayroll == 5 && wac.TypeIncident == 21 && this.HaveResponsability==true" cols="6" class="s-col-form">
                            <s-text v-model="wac.WacPersonResponsability" ref="focusResponsability" label="Presentarse con:"></s-text>
                          </v-col>
                          <v-col cols="3" class="s-col-form" v-if="this.remote">
                            <s-text label="N°.Papeleta" v-model="wac.WacBullotNumber" />
                          </v-col>
                          <v-col cols="3" class="s-col-form" v-if="this.remote">
                            <s-text label="Cod.Reg." v-model="wac.WacFileNumber" />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3" class="s-col-form">
                            <s-date v-model="wac.WacBeginDate" label="Fecha de Inicio" @input="isCalculateDays(wac)" />
                          </v-col>
                          <v-col cols="3" class="s-col-form">
                            <s-date v-model="wac.WacEndDate" label="Fecha de Fin" nullable @input="isCalculateDays(wac)" clearable />
                          </v-col>
                          <v-col cols="1" class="s-col-form">
                            <s-text label="N°.Dias" v-model="wac.WacTotalDays" disabled />
                          </v-col>
                          <v-col cols="2" class="s-col-form" v-if="this.defaulttime && this.remote">
                            <s-time v-model="wac.WacStartTime" label="T.Inicio" @input="isCalculateTime(wac)" clearable />
                          </v-col>
                          <v-col cols="2" class="s-col-form" v-if="this.defaulttime && this.remote">
                            <s-time v-model="wac.WacFinalTime" label="T.Final" @input="isCalculateTime(wac)" clearable />
                          </v-col>
                          <v-col cols="1" class="s-col-form" v-if="this.defaulttime && this.remote" disabled>
                            <s-text label="T.H" v-model="wac.WacTotalHours" disabled />
                          </v-col>
                          <v-col lg="2" class="s-col-form" v-if="!this.remote">
                            <s-switch v-model="switchProgramer" label="Habilitar Ingreso"></s-switch>
                          </v-col>
                        </v-row>
                        <v-row v-if="this.default">
                          <v-col cols="3" class="s-col-form">
                            <s-select-definition :def="1042" v-model="wac.WacStatus" label="Originado por"></s-select-definition>
                          </v-col>
                          <v-col cols="8" class="s-col-form">
                            <s-text label="Diagnostico" v-model="wac.MdrDiagnosis" />
                          </v-col>
                        </v-row>
                        <v-row v-if="this.justify">
                          <v-col cols="3" class="s-col-form">
                            <s-select-definition :def="1043" v-model="wac.TypeMedicalCertificate" label="Tipo Certificado"></s-select-definition>
                          </v-col>
                          <v-col cols="3" class="s-col-form">
                            <s-text label="N°Certificado" v-model="wac.MdrCertificateNumber" />
                          </v-col>
                          <v-col cols="6" class="s-col-form">
                            <s-text label="Observacion Certificado" v-model="wac.MdrObservation" />
                          </v-col>
                        </v-row>

                        <v-row v-if="this.remote">
                          <v-col cols="4" class="s-col-form">
                            <s-select-definition :def="1058" v-model="wac.TypeDeduct" label="Consideraciones"></s-select-definition>
                          </v-col>
                          <v-col cols="8" class="s-col-form">
                            <s-text label="Observación" v-model="wac.WacObservation" />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="s-col-form">
                            <v-btn small block text @click="Return($event)">Regresar</v-btn>
                          </v-col>
                          <v-col class="s-col-form">
                            <v-btn small block color="primary" @click="saveWac(wac)">Guardar</v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-col>
                </v-card-text>
              </v-card>
            </template>
          </v-tab-item>
        </v-tabs>
      </v-col>

      <v-dialog v-model="loadingMasive" v-if="this.loadingMasive" persistent max-width="70%">
        <v-card>
          <v-toolbar dense elevation="2" flat class="text-center">
            <v-btn icon @click="CloseMasive()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Control de Adición Masiva de Contingencias</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text x-small @click="deleteItem()">
              <v-icon color="error">mdi-minus-circle-outline</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row
              ><v-col lg="12"><v-label>Contingencias Masivas</v-label> </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col class="s-col-form" sm="3" md="3">
                <s-select-definition :def="1040" :dgr="24" v-model="masiveObject.TypeIncident" label="Incidencia"></s-select-definition>
              </v-col>
              <v-col class="s-col-form" sm="9" md="9">
                <s-import-excel @clear="items = []" @changeData="changeFile($event)"></s-import-excel>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="s-col-form" sm="12" md="12">
                <v-data-table
                  dense
                  :single-select="true"
                  :headers="headers"
                  @click:row="rowClick"
                  :items="items"
                  locale="es-ES"
                  selectable-key="isSelectable"
                  :items-per-page="5"
                  item-key="ID"
                  ref="stable"
                  v-model="selected"
                  :footer-props="{
                    showCurrentPage: true,
                    showPageText: false,
                    showFirstLastPage: false,
                    showPerPageOptions: false,
                    showPerPageText: false,
                    itemsPerPageAllText: 'Todos',
                    noResultsText: 'sin resultados',
                    noDataText: 'sin resultados',
                    itemsPerPageOptions: [10, 25, 50, -1],
                    itemsPerPageText: 'Registros',
                  }"
                >
                  <template slot:item.WacStatus="{ item }"> </template>

                  <template slot="no-data"> Sin Datos </template>
                  <template slot="no-results"> Sin Datos </template>
                  <template slot:footer.page-text="{ pageStop, itemsLength }">
                    {{ itemsLength }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row>
              <v-col class="s-col-form"><v-btn block text @click="CloseMasive()">Cancelar</v-btn></v-col>
              <v-col class="s-col-form"><v-btn block color="primary" @click="save()">GUARDAR</v-btn></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import _sWorkerAllowance from "@/services/HumanResource/WorkerAllowanceService";
import sSelectWorker from "@/components/Utils/Worker/sSelectWorker";
import _sPerson from "@/services/General/PersonService";
import _sAccessSchedulingService from "./../../services/HumanResource/AccessSchedulingService";
import moment from "moment";
export default {
  name: "wWorkerAllowance",
  components: {
    sSelectWorker,
  },
  data: () => ({
    //Real
    tab: "tab-show",
    wac: {},
    objetreport: false,
    default: false,
    remote: true,
    justify: true,
    loadingMasive: false,
    defaulttime: false,

    filter: {
      TypePayroll: 0,
      WacBeginDate: null,
      WacEndDate: null,
      Everybody: 0,
    },
    masiveObject: {
      TypePayroll: null,
      WacBeginDate: null,
      WacEndDate: 0,
      TypeIncident: 0,
      TypeGroup: 0,
    },

    config: {
      model: {
        WacID: "ID",
        WacFileNumber: "string",
        TypeIncidentText: "string",
        PrsDocumentNumber: "string",
        WkrName: "string",
        WacBeginDate: "date",
        WacEndDate: "date",
        WacTotalDays: "int",
        DataWorker: "",
      },

      service: _sWorkerAllowance,

      headers: [
        {
          text: "ID",
          align: "start",
          width: "5%",
          sortable: false,
          value: "WacID",
        },
        {
          text: "N°.",
          width: "5%",
          value: "WacBullotNumber",
          sorteable: true,
        },
        {
          text: "Contingencia",
          width: "15%",
          sortable: true,
          value: "TypeIncidentText",
        },
        {
          text: "N°.Doc.",
          width: "5%",
          value: "PrsDocumentNumber",
        },
        {
          text: "Trabajador",
          value: "WkrName",
        },
        {
          text: "F.Inicio",
          align: "center",
          value: "WacBeginDate",
        },
        {
          text: "F.Fin",
          align: "center",
          value: "WacEndDate",
        },
        {
          text: "Cnt.Dias",
          width: "5%",
          align: "center",
          value: "WacTotalDays",
        },
      ],
    },

    //Masive
    selected: [],
    worker: {},
    HaveResponsability : false,

    date: "",
    document: "",
    dateend: "",
    status: 0,
    mensaje: "",
    page: 1,
    items: [],
    headers: [
      { text: "ID", value: "ID", sortable: false },
      { text: "DNI", value: "PrsDocumentNumber", sortable: false },
      { text: "FECHA INICIO", value: "WacBeginDate", sortable: false },
      { text: "FECHA FIN", value: "WacEndDate", sortable: false },
      { text: "MSJ", value: "WacMsj", sortable: false },
    ],
    switchProgramer: false,
    itemsLength: 0,
  }),
  created() {
    this.filter = {
      TypePayroll: 5,
      WacBeginDate: this.$fun.getDate(),
      WacEndDate: this.$fun.getDate(),
    };
  },
  methods: {
    initialize() {
      this.$nextTick().then(() => {
        if (this.wac.WacID > 0) {
          _sWorkerAllowance.get(this.wac, this.$fun.getUserID()).then((x) => {
            this.wac = x.data;
            console.log(this.wac);
            this.wac.DataWorker = {
              ...x.data,
            };
          });
        } else
          this.wac = {
            WacID: 0,
            WkrID: 0,
            TypeGroup: 0,
            TypeIncident: 0,
            WacBullotNumber: "",
            WacPersonResponsability: "",
            WacFileNumber: "",
            WacEndDate: this.$fun.getDate(),
            WacBeginDate: this.$fun.getDate(),
            WacTotalDays: 0,
            TypeWorkerAllowance: 0,
            TypeMedicalCertificate: 0,
            TypeDeduct: 0,
            DataWorker: "",
          };
      });
    },

    addAllowance(item) {
      this.tab = "tab-edit";
      this.wac = {};
      this.default = false;
    },
    editAllowance(item) {
      this.tab = "tab-edit";
      this.wac.WacID = item.WacID;
      this.initialize();
    },

    Return(item) {
      this.tab = "tab-show";
      this.$refs.crudworkerallowance.refresh();
    },
    selectType(value, item) {
      this.remote = true;
      console.log(value);
      if (value == 10) {
        //permisos justificados
        this.defaulttime = true;
        this.justify = false;
        this.default = false;
        this.HaveResponsability = false;
        item.WacEndDate = this.$fun.getDate();
        this.isCalculateDays(item);
      } else if (value == 24) {
        this.remote = false;
        item.TypeDeduct = 2;
        item.WacTotalDays = 0;
        item.WacEndDate = null;
        this.HaveResponsability = true;
        this.justify = false;
      } else {
        this.justify = true;
        this.defaulttime = false;
        item.WacBeginDate = this.$fun.getDate();
        item.WacEndDate = this.$fun.getDate();
        item.WacTotalHours = 0;
        this.HaveResponsability = false;
        this.isCalculateDays(item);
      }
    },
    selectIncident(value, type) {
      if (value != null && type != null) {
        if (type == 10) {
          if (value == 16 || value == 14) {
            this.default = true;
          } else {
            this.default = false;
            this.justify = false;
          }
        } else {
          if (value == 3 || value == 6) {
            this.default = true;
            this.justify = true;
          } else if (value == 1 || value == 2 || value == 5) {
            this.default = false;
            this.justify = true;
          } else {
            this.default = false;
            this.justify = false;
          }
        }
      }
    },
    returnObject() {},
    isValidate(worker) {
      let isValid;
      let message;

      isValid = worker.TypePayroll > 0;
      if (!isValid) message = "Seleccione tipo de planilla";
      else {
        isValid = worker.WkrID > 0;
        if (!isValid) message = "Seleccione trabajador";
        else {
          isValid = worker.TypeGroup > 0;
          if (!isValid) message = "Seleccione Permiso";
          else {
            isValid = worker.TypeIncident > 0;
            if (!isValid) message = "Seleccione Incidencia";
            else {
              if (this.remote) {
                isValid = worker.WacTotalDays > 0;
                if (!isValid) message = "Numeros de dias!!!";
              }

              if (this.default) {
                isValid = worker.TypeWorkerAllowance > 0;
                if (!isValid) message = "Seleccione origen";
                else {
                  isValid = worker.MdrDiagnosis != null;
                  if (!isValid) message = "Registre Diagnostico";
                  if (this.justify) {
                    isValid = worker.TypeMedicalCertificate > 0;
                    if (!isValid) message = "Seleccione tipo de certificado";
                    else {
                      isValid = worker.MdrCertificateNumber != null;
                      if (!isValid) message = "Registr N° Certificado";
                      else {
                        isValid = worker.MdrCertificateNumber != null;
                        if (!isValid) message = "Registr N° Certificado";
                        else {
                          isValid = worker.TypeDeduct > 0;
                          if (!isValid) message = "Selecciones Consideracion";
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },

    saveWac(item) {
      var mdr = {};
      var wac = {};
      if (item.DataWorker == null) return this.$fun.alert("Seleccione Trabajador", "warning");

      if(this.HaveResponsability == true && item.TypePayroll == 5 && item.TypeIncident == 21) {

        if(item.WacPersonResponsability == null || item.WacPersonResponsibility == ""){
        this.$refs.focusResponsability.focus();
        return this.$fun.alert("Ingrese la persona con quien debe presentarse", "warning");
        
        }
      }
      

      item.WkrID = item.DataWorker.WkrID;
      item.CtrID = item.DataWorker.CtrID;
      item.TypeWorkerAllowance = item.DataWorker.TypePayroll;
      if (this.isValidate(item)) {
        if (this.default || this.justify) {
          wac = {
            WacID: item.WacID,
            WkrID: item.DataWorker.WkrID,
            CtrID: item.DataWorker.CtrID,
            WacTotalDays: item.WacTotalDays,
            WacTotalHours: item.WacTotalHours,
            WacBullotNumber: item.WacBullotNumber,
            WacPersonResponsability: item.WacPersonResponsability,
            TypeWorkerAllowance: item.TypeWorkerAllowance,
            WacBeginDate: item.WacBeginDate,
            WacEndDate: item.WacEndDate,
            WacTotalDays: item.WacTotalDays,
            WacTotalHours: item.WacTotalHours,
            TypeGroup: item.TypeGroup,
            TypeIncident: item.TypeIncident,
            WacOtherExpense: item.WacOtherExpense,
            WacFileNumber: item.WacFileNumber,
            WacObservation: item.WacObservation,
            WacStatus: item.WacStatus,
            UsrID: this.$fun.getUserID(),
            WacStartTime: item.WacStartTime,
            WacFinalTime: item.WacFinalTime,
            TypeDeduct: item.TypeDeduct,
            SecStatus: 1,
            HmnMedicRest: [
              {
                WacID: item.WacID,
                MdrID: item.MdrID,
                MdrDiagnosis: item.MdrDiagnosis,
                TypeMedicalCertificate: item.TypeMedicalCertificate,
                MdrCertificateNumber: item.MdrCertificateNumber,
                MdrObservation: item.MdrObservation,
                UsrCreateID: this.$fun.getUserID(),
                //EN NINGUN MOMENTO LE PASA EL ID DEL DATALLE QUE VA ACTUALIZAR PA
                // por eso
              },
            ],
          };
        } else {
          wac = {
            WacID: item.WacID,
            WkrID: item.DataWorker.WkrID,
            CtrID: item.DataWorker.CtrID,
            WacTotalDays: item.WacTotalDays,
            WacTotalHours: item.WacTotalHours,
            WacBullotNumber: item.WacBullotNumber,
            WacPersonResponsability: item.WacPersonResponsability,
            TypeWorkerAllowance: item.DataWorker.TypePayroll,
            WacBeginDate: item.WacBeginDate,
            WacEndDate: item.WacEndDate,
            WacTotalDays: item.WacTotalDays,
            WacTotalHours: item.WacTotalHours,
            TypeGroup: item.TypeGroup,
            TypeIncident: item.TypeIncident,
            WacOtherExpense: item.WacOtherExpense,
            WacFileNumber: item.WacFileNumber,
            WacObservation: item.WacObservation,
            WacStatus: item.TypeWorkerAllowance,
            UsrID: this.$fun.getUserID(),
            WacStartTime: item.WacStartTime,
            WacFinalTime: item.WacFinalTime,
            TypeDeduct: item.TypeDeduct,
            SecStatus: 1,
            HmnMedicRest: {},
          };
        }

        _sWorkerAllowance.save(wac, this.$fun.getUserID()).then((response) => {
          if (response.status == 200) {
            let obj = Object.assign({}, response);
            if (wac.WacID == 0) {
              this.$fun.alert("Agregado Correctamente", "success");
            } else {
              this.$fun.alert("Actualizado Correctamente", "success");
            }
          }
        });
      }

      //Guardar Control Acces
      if (this.switchProgramer == true) {
        let objList = [];
        var register = {};

        register = {
          AcsID: 0,
          NtpID: item.DataWorker.NtpID,
          PrsDocumentNumber: item.DataWorker.PrsDocumentNumber,
          AcsDate: item.WacEndDate,
          TypeSchedule: 1,
        };
        objList.push(register);

        _sAccessSchedulingService.saveMassive(objList, this.$fun.getUserID()).then((r) => {
          if (r.status == 200) {
            this.$fun.alert("Agregados Correctamente", "success");
          }
        });
      }
    },

    isCalculateDays(item) {
      if (item != null) {
        var diascalculados = 0;
        console.log(item);
        if (item.WacBeginDate != null || item.WacBeginDate == "") {
          diascalculados = moment(item.WacEndDate, "YYYY-MM-DD").diff(moment(item.WacBeginDate, "YYYY-MM-DD"), "days");
          item.WacTotalDays = diascalculados + 1;
        } else item.WacTotalDays = diascalculados;
      }
    },
    isCalculateTime(item) {
      var hour = 0;
      if (item != null) {
        console.log(item);
        hour = moment(item.WacFinalTime, "HH:mm:ss").diff(moment(item.WacStartTime, "HH:mm:ss"), "hours");
        item.WacTotalHours = hour;
      }
      item.WacTotalHours = 0;
    },
    //Adicion masiva de contingencias
    AddMasive(liquidation) {
      if (liquidation) {
        this.loadingMasive = true;
        this.items = [];
      }
    },
    CloseMasive() {
      this.loadingMasive = false;
      this.$refs.crudworkerallowance.refresh();
    },
    clearForm() {
      this.document = "";
      this.date = this.$moment().format("YYYY-MM-DD");
      this.dateend = this.$moment().format("YYYY-MM-DD");
      this.status = "";
      this.mensaje = "";
    },
    rowClick: function (item, row) {
      this.selected = [item];
      this.row = Object.assign({}, item);
      console.log("seleccionando");
      this.$emit("rowSelected", this.selected);
      
    },
    addItem() {
      this.items.push({
        PrsDocumentNumber: this.document,
        WacBeginDate: this.date,
        WacEndDate: this.dateend,
        WacMsj: this.mensaje,
      });
      this.clearForm();
    },
    deleteItem() {
      this.items = this.items.filter((x) => this.row.PrsDocumentNumber != x.PrsDocumentNumber);
    },
    changeFile(data) {
      let item = {};
      data.forEach((element) => {
        item = {
          PrsDocumentNumber: element.DNI,
          WacBeginDate: this.$moment(element.INICIO).format("YYYY-MM-DD"),
          WacEndDate: element.FIN === undefined ? null : this.$moment(element.FIN).format("YYYY-MM-DD"),
        };

        item.ID = this.items.length + 1;
        this.items.push(item);
      });
    },
    save() {
      this.items.forEach((element) => {
        element.TypeIncident = this.masiveObject.TypeIncident;
        element.WacTotalDays =
          element.WacEndDate == undefined ? 0 : moment(element.WacEndDate, "YYYY-MM-DD").diff(moment(element.WacBeginDate, "YYYY-MM-DD"), "days") + 1;
        element.WacTotalHours = 0;
        element.TypeGroup = 24;
      });

      console.log('djdkddj', this.items);
      this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
        if (val.value) {
          _sWorkerAllowance.saveMassive(this.items, this.$fun.getUserID()).then((r) => {
            if (r.status == 200) {
              this.refresh = !this.refresh;

              var clon = this.items;
              var register = this.items.length;

              var add = [];

              _.each(r.data, function (objeto) {
                clon = clon.filter((x) => objeto.PrsDocumentNumber == x.PrsDocumentNumber);

                let item = {
                  ID: objeto.WacID,
                  PrsDocumentNumber: objeto.PrsDocumentNumber,
                  WacBeginDate: objeto.WacBeginDate.slice(0, 10),
                  WacEndDate: objeto.WacEndDate === null ? null : objeto.WacEndDate.slice(0, 10),
                  WacMsj: objeto.WacObservation,
                };
                add.push(item);
              });
              this.items = [];
              this.items = add;

              if (register - r.data.length > 0) {
                this.$fun.alert("Agregados Correctamente " + (register - r.data.length), "success");
              } //else this.$fun.alert("Verifique mensaje de los registros ", "warning");
            }
          });
        }
      });
    },
  },
};
</script>
